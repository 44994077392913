<template>
  <div class=container>
      <table class=table width="73%" border="0" cellspacing="30" cellpadding="0">
          <tbody><tr>
            <td><div align="center"><font size="+2">3D Scanning in the Presence of Shadows</font></div></td>
          </tr>
          <tr>
            <td>Here, we show several examples of 3D scanning in the presence of shadows. In each case, the 3D scanning technique used was phase-shifting of sinusoidal patterns. <strong>By clicking on each of the thumbnail images shown below, you can see the corresponding high resolution image.</strong><br>
 <router-link :to="{path:'/projects/categories/project',query:{cid:this.$route.query.cid,pid:this.$route.query.pid}}">Back to Main Project Page</router-link></td>
          </tr>
          <tr>
            <td><table width="100%" border="1" cellspacing="0" cellpadding="0">
              <tbody><tr>
                <td><table width="100%" border="0" cellspacing="10" cellpadding="0">
                  <tbody><tr>
                    <td><strong>Cube</strong></td>
                    <td><div align="right"><strong>Method: Phase-Shifting<br>
                    </strong></div></td>
                  </tr>
                </tbody></table>
                  <div align="right"></div></td>
                </tr>
              <tr>
                <td><table width="100%" border="0" cellspacing="10" cellpadding="0">
                  <tbody><tr>
                    <td width="32%"><div align="center"><a href="https://cave.cs.columbia.edu/old/projects/diffuse_sl/images/cube/Image-Processed.png"><img src="https://cave.cs.columbia.edu/old/projects/diffuse_sl/images/cube/Image-Processed.png" width="259" height="202" border="0"></a><br>
                          <span class="style1">.</span><br>
                          <strong>Scene</strong></div></td>

                  </tr>
                </tbody></table>
                  <table width="100%" border="0" cellspacing="10" cellpadding="0">
                    <tbody><tr>
                      <td>This example consists of a cube on a flat plane. The projector is above the cube - the direction of projection is normal to the top face of the cube.</td>
                    </tr>
                    <tr>
                      <td>&nbsp;</td>
                    </tr>
                  </tbody></table></td>
                </tr>
              <tr>

                <td><table width="100%" border="0" cellspacing="10" cellpadding="0">
                  <tbody><tr>
                    <td width="32%"><div align="center"><a href="https://cave.cs.columbia.edu/old/projects/diffuse_sl/images/cube/Cube-ND.gif"><img src="https://cave.cs.columbia.edu/old/projects/diffuse_sl/images/cube/Cube-ND.gif" width="279" height="230" border="0"></a><br>
                          <span class="style1">.</span><br>
                          <strong>Input (conventional SL) </strong></div></td>
                    <td width="35%"><div align="center"><a href="https://cave.cs.columbia.edu/old/projects/diffuse_sl/images/cube/Cube-WD.gif"><img src="https://cave.cs.columbia.edu/old/projects/diffuse_sl/images/cube/Cube-WD.gif" width="279" height="230" border="0"></a><br>
                      <span class="style1">.</span><br>
                      <strong>Input (diffuse SL) </strong></div></td>
                    </tr>
                </tbody></table>

                <table width="100%" border="0" cellspacing="10" cellpadding="0">
                  <tbody><tr>
                    <td width="32%"><div align="center"><a href="https://cave.cs.columbia.edu/old/projects/diffuse_sl/images/cube/WhiteCube3D-ND00.png"><img src="https://cave.cs.columbia.edu/old/projects/diffuse_sl/images/cube/WhiteCube3D-ND00.png" width="279" height="265" border="0"></a><br>
                          <span class="style1">.</span><br>
                          <strong>Reconstruction (conventional SL) </strong></div></td>
                    <td width="35%"><div align="center"><a href="https://cave.cs.columbia.edu/old/projects/diffuse_sl/images/cube/WhiteCube3D-WD00.png"><img src="https://cave.cs.columbia.edu/old/projects/diffuse_sl/images/cube/WhiteCube3D-WD00.png" width="279" height="265" border="0"></a><br>
                      <span class="style1">.</span><br>
                      <strong>Reconstruction (diffuse SL) </strong></div></td>
                    </tr>
                </tbody></table>



                <table width="100%" border="0" cellspacing="10" cellpadding="0">
                    <tbody><tr>
                      <td> With conventional SL, the two vertical faces of the cube are in shadow. The cube also casts a shadow on the plane. Depth cannot be computed for the shadow regions, resulting in large holes. With a diffuser, these regions of the scene also receive illumination. Notice the projected stripes on the vertical walls and near the base of the cube in the input sequence for diffuse SL. As a result, the coverage (fraction of the reconstruction scene) of the scene with diffuse SL is significantly higher compared to conventional SL.</td>
                    </tr>
                    <tr>
                      <td>&nbsp;</td>
                    </tr>
                  </tbody></table>




                </td></tr><tr>

                </tr>
          </tbody></table></td>
          </tr>






          <tr>
            <td><table width="100%" border="1" cellspacing="0" cellpadding="0">
                <tbody><tr>
                  <td><table width="100%" border="0" cellspacing="10" cellpadding="0">
                      <tbody><tr>
                        <td width="47%"><strong>Fingerprint</strong></td>
                        <td width="53%"><div align="right"><strong>Method: Phase-Shifting<br>
                        </strong></div></td>
                      </tr>
                    </tbody></table>
                      <div align="right"></div></td>
                </tr>
                <tr>
                  <td><table width="100%" border="0" cellspacing="10" cellpadding="0">
                      <tbody><tr>
                        <td width="32%"><div align="center"><a href="https://cave.cs.columbia.edu/old/projects/diffuse_sl/images/finger/IMAGE-WD.png"><img src="https://cave.cs.columbia.edu/old/projects/diffuse_sl/images/finger/IMAGE-WD.png" width="203" height="166" border="0"></a><br>
                                <span class="style1">.</span><br>
                                <strong>Scene</strong></div></td>
                        <td width="35%"><div align="center"><a href="https://cave.cs.columbia.edu/old/projects/diffuse_sl/images/finger/FingerPrint3D-ND00-Processed.png"><img src="https://cave.cs.columbia.edu/old/projects/diffuse_sl/images/finger/FingerPrint3D-ND00-Processed.png" width="164" height="166" border="0"></a><br>
                                <span class="style1">.</span><br>
                                <strong>Conventional SL</strong></div></td>
                        <td width="33%"><div align="center"><a href="https://cave.cs.columbia.edu/old/projects/diffuse_sl/images/finger/FingerPrint3D-WD00-Processed.png"><img src="https://cave.cs.columbia.edu/old/projects/diffuse_sl/images/finger/FingerPrint3D-WD00-Processed.png" width="164" height="166" border="0"></a><br>
                                <span class="style1">.</span><br>
                                <strong>Diffuse SL</strong></div></td>
                      </tr>
                    </tbody></table>
                      <table width="100%" border="0" cellspacing="10" cellpadding="0">
                        <tbody><tr>
                          <td> Here, the goal is to recover the 3D structure of the fingerprint. Without the diffuser, a large portion of the finger tip is in shadow, resulting in noisy reconstruction. With the diffuser, most of the finger receives illumination, resulting in an accurate reconstruction. Click on the thumbnails for higher resolution images. </td>
                        </tr>
                        <tr>
                          <td>&nbsp;</td>
                        </tr>
                    </tbody></table></td>
                </tr>
            </tbody></table></td>
          </tr>
          <tr>
            <td><table width="100%" border="1" cellspacing="0" cellpadding="0">
                <tbody><tr>
                  <td><table width="100%" border="0" cellspacing="10" cellpadding="0">
                      <tbody><tr>
                        <td width="47%"><strong>Pine Cone</strong></td>
                        <td width="53%"><div align="right"><strong>Method: Phase-Shifting<br>
                        </strong></div></td>
                      </tr>
                    </tbody></table>
                      <div align="right"></div></td>
                </tr>
                <tr>
                  <td><table width="100%" border="0" cellspacing="10" cellpadding="0">
                      <tbody><tr>
                        <td width="32%"><div align="center"><a href="https://cave.cs.columbia.edu/old/projects/diffuse_sl/images/pinecone/IMG_6494.png"><img src="https://cave.cs.columbia.edu/old/projects/diffuse_sl/images/pinecone/IMG_6494.png" width="203" height="314" border="0"></a><br>
                                <span class="style1">.</span><br>
                                <strong>Scene</strong></div></td>
                        <td width="35%"><div align="center"><a href="https://cave.cs.columbia.edu/old/projects/diffuse_sl/images/pinecone/PineCone3DVis-ND00-Processed.png"><img src="https://cave.cs.columbia.edu/old/projects/diffuse_sl/images/pinecone/PineCone3DVis-ND00-Processed.png" width="203" height="314" border="0"></a><br>
                                <span class="style1">.</span><br>
                                <strong>Conventional SL</strong></div></td>
                        <td width="33%"><div align="center"><a href="https://cave.cs.columbia.edu/old/projects/diffuse_sl/images/pinecone/PineCone3DVis-WD00-Processed.png"><img src="https://cave.cs.columbia.edu/old/projects/diffuse_sl/images/pinecone/PineCone3DVis-WD00-Processed.png" width="203" height="314" border="0"></a><br>
                                <span class="style1">.</span><br>
                                <strong>Diffuse SL</strong></div></td>
                      </tr>
                    </tbody></table>
                      <table width="100%" border="0" cellspacing="10" cellpadding="0">
                        <tbody><tr>
                          <td>This example consists of a pine-cone. Due to its intricate shape, a large portion is in shadow with conventional SL. This results in holes in the reconstructed shape. With diffuse SL, a larger portion of the cone receives illumination. As a result, the reconstruction is more complete. </td>
                        </tr>
                        <tr>
                          <td>&nbsp;</td>
                        </tr>
                    </tbody></table></td>
                </tr>
            </tbody></table></td>
          </tr>
          <tr>
            <td>&nbsp;</td>
          </tr>
        </tbody></table>
  </div>
</template>

<script>
export default {

}
</script>

<style>
.container{
    width:70%;
    margin-left:auto;
    margin-right:auto;
    text-align: left;
	font-size: 18px;
}
.table{
        margin:auto;
}
</style>